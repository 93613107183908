import React, { useRef } from 'react';
import styles from './EducationModule.module.scss';
import Typography from '~/components/shared/Typography';
import useIntersectionObserver from '~/helpers/hooks/useIntersectionObserver';
import { fireElementViewedEvent } from '~/helpers/utils/fireTagular';
import Accordion from '~/components/shared/AccordionBrand';
import { useMedia } from '~/helpers/hooks';
import PhoneNumber from '~/components/shared/Fuse/PhoneNumber';
import Button from '~/components/shared/Button';
import { PhoneBlueV2 } from '~/components/icons/PhoneBlueV2';
import LegalAnnotation from '~/components/shared/LegalAnnotation';
import { useMonarchPreampPlacementContext } from '@redventures/cohesion-utils-react';

const BASE_DATA = {
  backgroundColor: '#FAFBFD',
  headline: 'Say hello to the NEW Hughesnet',
  headlineTextColor: '#0096fa',
  pcaCta: 'CALL NOW',
  pcaHeader: 'Get all your questions answered today',
  pcaSubHead1:
    'Hughesnet Fusion plans might be available where you live. Fusion plans are great for those who want to enjoy a faster, more responsive Internet experience.',
  pcaSubHead2:
    'Chat through questions and plan details with an expert now. Learn about pricing, installation, data and more.',
  prop1HeaderTextColor: '#005dac',
  prop1Heading: 'This is satellite internet reinvented',
  prop1HeadingLegal: 'N/A',
  prop1HeadingLegalToggle: false,
  prop1IconColor: '#0096fa',
  prop1SubHeading1:
    'The new Hughesnet plans are powered by JUPITER<sup>TM</sup> 3, the largest, most advanced commercial communications satellite.',
  prop1SubHeading1Legal: 'N/A',
  prop1SubHeading1LegalToggle: false,
  prop1SubHeading2:
    'To get you online, we’ll install a satellite dish to send and receive information as well as a Wi-Fi modem, giving you secure and fast Internet access.',
  prop1SubHeading2Legal: 'N/A',
  prop1SubHeading2LegalToggle: false,
  prop1SubHeading2Toggle: false,
  prop1SubHeadingTextColor: '#333',
  prop2HeaderTextColor: '#005dac',
  prop2Heading: 'With the new Hughesnet you get unlimited data',
  prop2HeadingLegal: 'data-limit',
  prop2HeadingLegalToggle: true,
  prop2IconColor: '#0096fa',
  prop2SubHeading1:
    'All Hughesnet plans come with unlimited data, so you’ll never get cut off or pay overage fees. Now you can do more of what you love online.',
  prop2SubHeading1Legal: 'data-limit',
  prop2SubHeading1LegalToggle: false,
  prop2SubHeading2: 'N/A',
  prop2SubHeading2Legal: 'N/A',
  prop2SubHeading2LegalToggle: false,
  prop2SubHeading2Toggle: false,
  prop2SubHeadingTextColor: '#333',
  prop3HeaderTextColor: '#005dac',
  prop3Heading: 'Hassle-free installation by a certified professional',
  prop3HeadingLegal: 'N/A',
  prop3HeadingLegalToggle: false,
  prop3IconColor: '#0096fa',
  prop3SubHeading1:
    'A professional installer will bring your system right to your home. With Hughesnet, your Wi-Fi modem will be set up by your installer, who will connect up to two devices. Add optional Mesh Wi-Fi Nodes to eliminate dead zones in your home — also professionally installed when ordered with your Hughesnet system.',
  prop3SubHeading1Legal: '25-mbps-legal',
  prop3SubHeading1LegalToggle: false,
  prop3SubHeading2: 'N/A',
  prop3SubHeading2Legal: 'N/A',
  prop3SubHeading2LegalToggle: false,
  prop3SubHeading2Toggle: false,
  prop3SubHeadingTextColor: '#333',
  showJupiterLogo: true,
  showPCA: false,
  sideImage: 'woman-on-laptop',
  subhead:
    'Available where you live! Even in places that are beyond the reach of cable and fiber. Now, with faster speeds, Whole Home Wi-Fi, the low-latency Hughesnet Fusion plan and unlimited data.',
  subheadLegal: 'data-limit',
  subheadLegalToggle: true,
  subheadTextColor: '#535353',
};

export default function EducationModule() {
  const isMedia = useMedia();
  const { data: monarchData } = useMonarchPreampPlacementContext();
  const data = { ...BASE_DATA, ...monarchData };

  const {
    backgroundColor,
    headline,
    headlineTextColor,
    subhead,
    subheadTextColor,
    showPCA,
    pcaHeader,
    pcaSubHead1,
    pcaSubHead2,
    pcaCta,
    sideImage,
    subheadLegal,
    subheadLegalToggle,
  } = data;

  const eduAccordionRef = useRef();
  const educationModuleSection = useRef();
  useIntersectionObserver(
    [educationModuleSection],
    () =>
      fireElementViewedEvent(
        'education module',
        'education module section',
        'education module',
        headline
      ),
    undefined,
    { threshold: isMedia.desktop ? 1.0 : isMedia.tablet ? 0.75 : 0.5 }
  );

  const faqs = [];
  //Amount of props that are editable
  const amountProps = 3;
  //Loop through the monarchData for each prop and assign them on each object
  for (let i = 1; i <= amountProps; i++) {
    faqs.push({
      header: data[`prop${i}Heading`],
      headerLegal: data[`prop${i}HeadingLegal`],
      headerLegalToggle: data[`prop${i}HeadingLegalToggle`],
      headerTextColor: data[`prop${i}HeaderTextColor`],
      subhead1: data[`prop${i}SubHeading1`],
      subhead1Legal: data[`prop${i}SubHeading1Legal`],
      subhead1LegalToggle: data[`prop${i}SubHeading1LegalToggle`],
      subhead2: data[`prop${i}SubHeading2`],
      subhead2Toggle: data[`prop${i}SubHeading2Toggle`],
      subhead2Legal: data[`prop${i}SubHeading2Legal`],
      subhead2LegalToggle: data[`prop${i}SubHeading2LegalToggle`],
      subheadTextColor: data[`prop${i}SubHeadingTextColor`],
      iconColor: data[`prop${i}IconColor`],
      showJupiterLogo: data[`showJupiterLogo`],
    });
  }

  return (
    <section
      ref={educationModuleSection}
      style={{ backgroundColor: backgroundColor }}
    >
      <div className={styles.container}>
        <div className={styles.contentWrapper}>
          <div>
            <Typography variant='h2' className={styles.headline}>
              <span style={{ color: headlineTextColor }}>{headline}</span>
            </Typography>
          </div>
          <div>
            <Typography variant='pbolder' className={styles.subheading}>
              <span style={{ color: subheadTextColor }}>
                {subhead}
                {subheadLegalToggle && (
                  <LegalAnnotation annotation={subheadLegal} />
                )}
              </span>
            </Typography>
          </div>
          <div className={styles.image}>
            <picture>
              <img
                src={`/images/section/${sideImage}.jpg`}
                alt='Woman on laptop'
                media='(min-width: 640px)'
                className={styles.image}
              />
            </picture>
          </div>
        </div>
        <div className={styles.accordionWrapper}>
          <Accordion
            tracking={{
              position: 'EDU MODULE',
              location: 'EDU MODULE',
              elementType: 'BUTTON',
              actionOutcome: 'TOGGLE OPEN',
            }}
            faqs={faqs}
            isFaq={false}
            ref={eduAccordionRef}
          />
          {showPCA ? (
            <div className={styles.pca}>
              <Typography variant='h2' className={styles.pcaHeader}>
                {pcaHeader}
              </Typography>
              <div className={styles.pcaSubContainer}>
                <Typography variant='pbolder' className={styles.pcaSubCopy}>
                  {pcaSubHead1}
                </Typography>
                <Typography variant='pbolder' className={styles.pcaSubCopy}>
                  {pcaSubHead2}
                </Typography>
                <Button
                  className={styles.pcaCta}
                  fuse
                  tracking={{
                    position: '',
                    location: 'MASTHEAD',
                    elementType: 'BUTTON',
                    actionOutcome: 'click to call',
                    htmlId: 'MASTHEAD_C2C',
                  }}
                >
                  <PhoneBlueV2 />
                  &nbsp;&nbsp;
                  <span className={styles.pcaCtaCopy}>
                    {pcaCta}&nbsp;
                    <PhoneNumber />
                  </span>
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
}
