import React, { useRef } from 'react';
import styles from './Banner.module.scss';
import useIntersectionObserver from '~/helpers/hooks/useIntersectionObserver';
import { fireElementViewedEvent } from '~/helpers/utils/fireTagular';
import { useMedia } from '~/helpers/hooks';
import { useMonarchPreampPlacementContext } from '@redventures/cohesion-utils-react';

const BASE_DATA = {
  headerCopy:
    'Powered by JUPITER™ 3, the largest, most advanced commercial satellite.',
  headerFontColor: '#0e4275',
};

export default function Banner() {
  const { data: monarchData } = useMonarchPreampPlacementContext();
  const {
    headerFontColor,
    headerCopy,
    desktopBackgroundImage,
    mobileBackgroundImage,
  } = { ...BASE_DATA, ...monarchData };

  const bannerRef = useRef();
  const isMedia = useMedia();
  const bgImageDesktop = desktopBackgroundImage
    ? `/images/section/${desktopBackgroundImage}.jpg`
    : `/images/section/us-news-d.jpg`;
  const bgImageMobile = mobileBackgroundImage
    ? `/images/section/${mobileBackgroundImage}.jpg`
    : `/images/section/us-news-m.jpg`;

  useIntersectionObserver(
    [bannerRef],
    (el) =>
      fireElementViewedEvent(
        'US News Banner',
        'US News Banner Section',
        'US News Banner',

        headerCopy
      ),
    undefined,
    // Fire tagular only when the full element is visible
    { threshold: 1.0 }
  );

  return (
    <div className={styles.container} ref={bannerRef}>
      <section
        className={styles.content}
        style={{
          backgroundImage: isMedia.desktop
            ? `url(${bgImageDesktop})`
            : `url(${bgImageMobile})`,
        }}
      >
        <h1
          className={styles.headline}
          style={{ color: headerFontColor }}
          dangerouslySetInnerHTML={{ __html: headerCopy }}
        />
      </section>
    </div>
  );
}
