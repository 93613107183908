import React from 'react';

export const PhoneBlueV2 = (props) => {
  return (
    <svg
      width='15'
      height='14'
      viewBox='0 0 15 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.5 4V0H6.5V4L4 6.5L8 10.5L10.5 8H14.5V14H10.5C4.97715 14 0.5 9.52285 0.5 4Z'
        fill={props.color || '#154B8E'}
      />
    </svg>
  );
};
