import { useState, useEffect } from 'react';
import AccordionItem from './AccordionItem';
import styles from './Accordion.module.scss';

const Accordion = ({ tracking, faqs, isFaq = false }) => {
  const [clicked, setClicked] = useState(0);
  const handleToggle = (index, faq) => {
    if (clicked === index) {
      return setClicked('0');
    }

    if (tracking) {
      tagular('beam', 'ElementClicked', {
        '@type': 'redventures.usertracking.v3.ElementClicked',
        webElement: {
          location: tracking.location || '',
          position: tracking.position || '',
          elementType: tracking.elementType || '',
          text: faq.header || '',
          htmlId: tracking.htmlId || '',
        },
        actionOutcome: tracking.actionOutcome || '',
      });
    }
    setClicked(index);
  };

  useEffect(() => {
    setClicked(0);
  }, []);

  return (
    <div id={isFaq ? 'faq' : 'edu'}>
      <ul className={styles.accordion}>
        {faqs.map((faq, index) => (
          <AccordionItem
            onToggle={() => handleToggle(index, faq)}
            active={clicked === index}
            key={index}
            faq={faq}
            index={index}
            isFaq={isFaq}
          />
        ))}
      </ul>
    </div>
  );
};

export default Accordion;
