import React from 'react';
import Head from 'next/head';
import { HeroSkeleton } from '~/components/shared/Skeleton';
import MirHero from '~/components/rebrand/MIR Hero';
import ACP_Banner from '~/components/rebrand/ACP Banner';
import WholeWifiModule from '~/components/rebrand/Whole Home WiFi';
import EducationModule from '~/components/rebrand/Education Module';
import Banner from '~/components/rebrand/US News';
import ValueProps from '~/components/rebrand/Value Props';
import FinalEpq from '~/components/rebrand/Final EPQ';
import { MonarchPreampPlacement } from '@redventures/cohesion-utils-react';
import { getFilteredComponentName } from '~/helpers/utils/getFilteredComponentName';

const Home = () => {
  return (
    <>
      <Head>
        <title>
          Satellite Internet | 1-855-858-5504 | Find Your Satellite Internet
          Provider!
        </title>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta
          name='description'
          content='Satellite Internet is fast, affordable and always connected! Visit to learn more about satellite Internet today.'
        />
      </Head>

      {/* Hero Placement */}
      <MonarchPreampPlacement
        placement='4VdUdUM1j7gFHXes7NqVPF'
        skeleton={HeroSkeleton}
        componentId={getFilteredComponentName}
      >
        <MirHero />
      </MonarchPreampPlacement>

      {/* Banner Below Hero Placement */}
      <MonarchPreampPlacement
        placement='10P4eZlEej5Awmgo2YUGiC'
        componentId={getFilteredComponentName}
      >
        <ValueProps />
      </MonarchPreampPlacement>

      {/* Section 1 Placement */}
      <MonarchPreampPlacement
        placement='2TvlZ5qJXwIaUwILfRrflF'
        componentId={getFilteredComponentName}
      >
        <ACP_Banner />
      </MonarchPreampPlacement>

      {/* Section 2 Placement */}
      <MonarchPreampPlacement
        placement='6APp9Tgx9kgvtT9Jzirp35'
        componentId={getFilteredComponentName}
      >
        <WholeWifiModule />
      </MonarchPreampPlacement>

      {/* Section 3 Placement */}
      <MonarchPreampPlacement
        placement='3ETuJls1gI4Mkd5hdbr6aL'
        componentId={getFilteredComponentName}
      >
        <EducationModule />
      </MonarchPreampPlacement>

      {/* Section 4 Placement */}
      <MonarchPreampPlacement
        placement='1hEJvSU7FGLiN7OF21b6Sn'
        componentId={getFilteredComponentName}
      >
        <Banner />
      </MonarchPreampPlacement>

      {/* Section 5 Placement */}
      <MonarchPreampPlacement
        placement='50oFaqrrR5LUqnMZrcVinK'
        componentId={getFilteredComponentName}
      >
        <FinalEpq />
      </MonarchPreampPlacement>

      {/* Section 6 Placement */}
      <MonarchPreampPlacement
        placement='7FKlHEBWSnMJwN30vK4Dcb'
        componentId={getFilteredComponentName}
      />

      {/* Section 7 Placement */}
      <MonarchPreampPlacement
        placement='1aNLmdEcqxidyMp6SwfjVj'
        componentId={getFilteredComponentName}
      />

      {/* Section 8 Placement */}
      <MonarchPreampPlacement
        placement='5MSDldnvFD5Y0SgQbIGQNZ'
        componentId={getFilteredComponentName}
      />

      {/* Return Visitor Modal */}
      <MonarchPreampPlacement
        placement='1WxHXlKAqe23e3dysil9KS'
        componentId={getFilteredComponentName}
      />
    </>
  );
};

export default Home;
